import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, Header, ContentContainer, Head, Content, Filler, Footer, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components'
import { Divider, Typography } from '@mui/material';
import { Phone } from '@mui/icons-material';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import { useNavigate } from "react-router-dom";

import uuid from 'react-uuid';
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 
import Paper from '@mui/material/Paper';
import { CircularProgress } from '@mui/material';
import Pagination from '@mui/material/Pagination';

import { 
  getSearchedOrders,
  resetState,
} from "../redux/actions";

var _ = require('lodash');


function SearchedOrdersView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer></Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}

function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const searchedOrders = stateObj.searchedOrders;
  const [page, setPage] = React.useState(0);
  

  const query = new URLSearchParams(window.location.search);
  const text = query.get('text');

  //console.log(searchedOrders);
  /*if (!_.isEmpty(searchedOrders)) {
    searchedOrders.items.map((v,i)=>{
      console.log(JSON.parse(v.product_options));
    }); 
  }*/
  
  useEffect(()=>{
    if (text) {
      if (text.trim() !== "") {
        dispatch(resetState({searchedOrders:null}));
        dispatch(getSearchedOrders(customerToken, parseInt(1), text, 4));
      }
    }
  },[]);

  const handleChangePage = (event, value) => {
    setPage(parseInt(value));
    
    dispatch(getSearchedOrders(customerToken, parseInt(value), text, 4));
  };

  let product_options = null;

  return(
    <Column width="100%" center>
      <Column width={lt.innerWidth} top={40} cross={Axis.cross.center}>
        <div style={{display: searchedOrders === null ? 'block': 'none'}}>
          <Row left={20} right={20} self={Axis.self.start} center='true'>
            <Header3>Please wait</Header3><Row left={20}><CircularProgress size={30}></CircularProgress></Row>
          </Row>
        </div>
        <div style={{display: searchedOrders !== null ? 'block': 'none'}}>
          { _.isEmpty(searchedOrders) === false &&
          <>
          <Column top={30} bottom={40} width={lt.innerWidth-40}  >
            { !_.isEmpty(searchedOrders) && searchedOrders.items.map((v,i)=>{
              product_options=JSON.parse(v.product_options);
              return (
                <Column key={uuid()} top={10} bottom={10} >
                  <Paper elevation={2}>
                    <Column left={30} right={30} top={30} bottom={30}>
                      <Row main={Axis.main.between} bottom={15}>
                        <Column>
                          <Row><Subtitle2>Order #</Subtitle2></Row>
                          <Row><Subtitle2>Date</Subtitle2></Row>
                          <Row><Subtitle2>Status</Subtitle2></Row>
                        </Column>
                        
                        <Column>
                          <Row main={Axis.main.end}><Subtitle2>{v.number}</Subtitle2></Row>
                          <Row main={Axis.main.end}><Subtitle2>{v.date}</Subtitle2></Row>
                          <Row main={Axis.main.end}><Subtitle2>{v.label}</Subtitle2></Row>
                        </Column>
                      </Row>
                      <Divider></Divider>
                      <Column top={15} bottom={15}>
                        <Row main={Axis.main.between} top={5} bottom={5}>
                          <Row><Caption>qty: {parseInt(product_options.info_buyRequest.qty)}</Caption></Row>
                        </Row>
                        { 
                          product_options.options.map((m,i)=>{
                            return(
                              <Row key={uuid()} top={5} bottom={5}>
                                <Row><Caption>{m.label}:</Caption></Row>
                                <Row left={15}><Body2>{m.value.replace(/&quot;/g, '"')}</Body2></Row>
                              </Row>
                            );
                          })
                        }
                      </Column>
                    </Column>
                  </Paper>
                </Column>
              );
            })}
          </Column>
          <Pagination count={Math.ceil(searchedOrders.totalRows/10)} page={page} onChange={handleChangePage} />
          <Row bottom={40}></Row>
          </>
          }
        </div>
      </Column>
    </Column>
  );
}









export default SearchedOrdersView16;