import '../App.css';
import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import { Row, Column, Axis, ContentContainer, Head, Content, Filler, Footer, Header, MainNav } from '../layout'; 
import { Color } from '../flatColors';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { Divider, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Phone } from '@mui/icons-material';
import { LogoContainer, HeaderButton, CallBackButton, HeaderNav, HeaderMenuButton, FormButton, FormTitle, FormSubTitle } from './Components';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { Link, useNavigate } from "react-router-dom";
import {Header1, Header2, Header3, Header4, Header5, Header6, Subtitle1, Subtitle2, Caption, Body1, Body2, ButtonText, Overline } from '../Components/TypographyComponents'; 
import { 
  logOutMagento, 
  getCart,
  deleteCustomerAddress,
  setCustomerAddressesAsDefalut,
  getAddresses,
  getCountries,
  getSearchedOrders,
  resetState,
  getCustomerInfo,
  getOrders,
} from "../redux/actions";
import { isTokenValid } from '../ApiHelper';
import NewAddressForm from '../Components/NewAddressForm';
import uuid from 'react-uuid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Close, Search } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import ReactToPrint from "react-to-print";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

var _ = require('lodash');



function ProfileView16() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const store = useStore();

  useEffect(()=>{
    return (()=>{
      dispatch(resetState({customerOrders:{}, errMessage:"", errType:""}));
      //console.log('umnount profile');
    });
  },[]);

  return(
    <ThemeProvider theme={store.getState().mainState.theme}>
      <ContentContainer >
        <Head>
          <Row width="100%" center='true'>
            <Header></Header>
          </Row>
        </Head>
        <MainNav>
        </MainNav>
        <Content>
          <Body></Body>
        </Content>
        <Filler></Filler>
        <Footer>
        </Footer>
      </ContentContainer>
    </ThemeProvider>
  );
}
 


function Body() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const navigate = useNavigate();
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const customerInfo = stateObj.customerInfo;
  const addresses = stateObj.addresses;
  const errMessage = stateObj.errMessage;

  const [menuSelected, selectMenu] = React.useState("my_orders");

  //console.log(customerToken);

  useEffect(()=>{
    isTokenValid(customerToken, (result)=>{
      if (!result) {
        dispatch(logOutMagento());
        navigate(`/`);
      } else {
        dispatch(getAddresses(customerToken));
        dispatch(getCustomerInfo(customerToken));
      }
    });
  }, [customerToken, JSON.stringify(addresses)]);

  let leftPanelWidth = lt.bunch4;
  let rightPanelWidth = lt.bunch12;
  if (lt.max === 12) {
    rightPanelWidth = lt.bunch8;
  } else if (lt.max === 8) {
    rightPanelWidth = lt.bunch4;
  }

  const menuHandler = (event)=>{
    const id = event.currentTarget.dataset.id;
    selectMenu(id);
  }
  

  return(
    <Column width="100%" center='true'>
      <div style={{display: lt.max >= 12 && lt.max <= 16 ? 'block' : 'none'}}>
        <Row width={lt.innerWidth} top={40} bottom={60} main={Axis.main.between} >
          <Column width={leftPanelWidth} cross={Axis.cross.center}  >
            <Column left={20} right={20} >
            <Paper sx={{ width: leftPanelWidth-20-20, maxWidth: '100%' }}>
              <MenuList>
                {/*<MenuItem data-id="my_account" onClick={menuHandler} sx={{backgroundColor: menuSelected==="my_account" ? Color.panelColor : 'inherit' }}>
                  <ListItemIcon>
                    <AccountCircleOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>My Account</ListItemText>
                </MenuItem>*/}
                <MenuItem data-id="my_orders" onClick={menuHandler} sx={{backgroundColor: menuSelected==="my_orders" ? Color.panelColor : 'inherit' }}>
                  <ListItemIcon>
                    <ReceiptOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>All Orders</ListItemText>
                </MenuItem>
                { _.isEmpty(customerInfo) === false && customerInfo.hasOwnProperty("group_id") && customerInfo.group_id === 4 &&
                <MenuItem data-id="order_statement" onClick={menuHandler} sx={{backgroundColor: menuSelected==="order_statement" ? Color.panelColor : 'inherit' }}>
                  <ListItemIcon>
                    <ReceiptOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Order Statement</ListItemText>
                </MenuItem>
                }
                {/*<Divider />*/}
                <MenuItem data-id="my_address_book" onClick={menuHandler} sx={{backgroundColor: menuSelected==="my_address_book" ? Color.panelColor : 'inherit' }}>
                  <ListItemIcon>
                    <ImportContactsOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Address Book</ListItemText>
                </MenuItem>
                {/*<MenuItem data-id="account_settings" onClick={menuHandler} sx={{backgroundColor: menuSelected==="account_settings" ? Color.panelColor : 'inherit' }}>
                  <ListItemIcon>
                    <SettingsApplicationsOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Account Settings</ListItemText>
                </MenuItem>*/}
                <Divider />
                <MenuItem data-id="log_out" onClick={menuHandler} sx={{backgroundColor: menuSelected==="log_out" ? Color.panelColor : 'inherit' }}>
                  <ListItemIcon>
                    <LogoutOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Log Out</ListItemText>
                </MenuItem>
              </MenuList>
            </Paper> 
            </Column>
          </Column>
          <Column width={rightPanelWidth} >
            <Column left={20} right={20} cross={Axis.cross.start}>
              { menuSelected === "my_account" &&
                <MyAccount></MyAccount>
              } 
              { menuSelected === "my_orders" &&
                <MyOrders></MyOrders>
              } 
              { menuSelected === "order_statement" &&
                <OrderStatement></OrderStatement>
              } 
              { menuSelected === "my_address_book" &&
                <MyAddressBook></MyAddressBook>
              } 
              { menuSelected === "account_settings" &&
                <AccountSettings></AccountSettings>
              } 
              { menuSelected === "log_out" &&
                <LogOut></LogOut>
              } 
            </Column>
          </Column>
        </Row>
      </div>
      <div style={{width:'calc(100% - 20px)', display: lt.max >= 4 && lt.max <= 8 ? 'block' : 'none'}}>
        <Column width='100%' top={20} bottom={60}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>All Orders</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MyOrders></MyOrders>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Address Book</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MyAddressBook></MyAddressBook>
            </AccordionDetails>
          </Accordion>
        </Column>
      </div>
    </Column>
  );
}



function MyAccount(props) {
  return (
    <Header4>My Account</Header4>
  );
}

function OrderStatement(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const customerToken = stateObj.customerToken;
  const allOrders = stateObj.allOrders;
  let date = new Date();
  date.setDate(date.getDate() - 1);
  let year = date.getFullYear();
  let mm = date.getMonth()+1;
  let dd = date.getDate();
  const yesterday = `${year}-${mm}-${dd}`;
  date = new Date();
  year = date.getFullYear();
  mm = date.getMonth()+1;
  dd = date.getDate();
  const today = `${year}-${mm}-${dd}`;

  const [fromDate, setFromDate] = React.useState(dayjs(yesterday));
  const [toDate, setToDate] = React.useState(dayjs(today));

  useEffect(()=>{
    
  }, [])

  console.log(customerToken);

  const getOrderReport = () => {
    dispatch(getOrders(customerToken, fromDate, toDate));
  }

  return(
    <Column>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopDatePicker
          disableFuture
          label="From date"
          //openTo="year"
          //views={['year', 'month', 'day']}
          value={fromDate}
          onChange={(newValue) => {
            setFromDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <DesktopDatePicker
          disableFuture
          label="To date"
          //openTo="year"
          //views={['year', 'month', 'day']}
          value={toDate}
          onChange={(newValue) => {
            setToDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
      </Stack>
    </LocalizationProvider>

    <Row top={40} bottom={40}>
      <Button onClick={getOrderReport} variant='contained' sx={{color:'white'}}>Report</Button>
    </Row>

    { allOrders &&
    <a target="_blank" href={"https://jrcastings.com/downloads/"+allOrders}>Download</a>
    }
    </Column>
  )
}



function MyOrders(props) {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const customerOrders = stateObj.customerOrders;
  const customerToken = stateObj.customerToken;
  const searchedOrders = stateObj.searchedOrders;
  const customerInfo = stateObj.customerInfo;
  const GROUP_SALES_PERSON = stateObj.customer_group_sales_person;

  const [page, setPage] = React.useState(0);

  const [searchText, setSearchText] = React.useState("");
  const searchRef = React.useRef();
  const navigate = useNavigate();

  //console.log(searchedOrders);
  
  const handleChangePage = (event, value) => {
    setPage(parseInt(value));
    dispatch(getSearchedOrders(customerToken, parseInt(value), "", 4, null));
  };

  const searchFieldChangeHandler = (event) => {
    let text = event.target.value.trim();
    setSearchText(text);
  }

  const searchCloseHandler = ()=>{
    searchRef.current.value = "";
  }

  const searchHandler = ()=>{
    dispatch(resetState({searchedOrders:[]}));
    navigate(`/search_orders/?text=${searchText}`);
  }

  //console.log(customerInfo);
  //console.log(customerToken);

  useEffect(()=>{
    if (page === 0) {
      setPage(1);
    }
    dispatch(resetState({searchedOrders:null}));
    if (customerInfo && customerInfo.hasOwnProperty('group_id') && customerInfo.group_id === GROUP_SALES_PERSON) {
      dispatch(getSearchedOrders(customerToken, parseInt(page), "", GROUP_SALES_PERSON, null));
    } else {
      dispatch(getSearchedOrders(customerToken, parseInt(page), "", 0, customerInfo.email));
    }
  }, [page, JSON.stringify(customerInfo)]);

  let totalPages = 0;
  if (searchedOrders !== null && searchedOrders.hasOwnProperty('totalRows')) {
    let p = Math.ceil(searchedOrders.totalRows/10);
    totalPages = p;
  }
  
  let items = [];
  if (customerOrders !== null && customerOrders.hasOwnProperty('items')) {
    items = customerOrders.items;
  }

  let widthPanel = lt.bunch12-20-20;
  if (lt.max === 12) {
    widthPanel = lt.bunch8-20-20;
  } else if (lt.max === 8) {
    widthPanel = lt.bunch8-20-20;
  } else if (lt.max === 4) {
    widthPanel = lt.bunch4-16-20-8;
  }

  /*const printHandler = (event) => {
    const details = JSON.parse(event.currentTarget.dataset.data);
    //sale_order(stateObj.apiRest, details, ()=>{

    //});

  }*/

  const printHandler = (event)=>{
    let obj = JSON.parse(event.currentTarget.dataset.data);
    dispatch(resetState({orderForPrint:obj}));
    navigate("/sale_order/?uuid="+uuid());
  }

  let product_options = null;

  return (
    <Column width="100%">
      <div style={{display: _.isEmpty(searchedOrders) ? 'none' : 'block'}}>
        <div style={{display: _.isEmpty(searchedOrders) === false && searchedOrders.hasOwnProperty('items') && _.isEmpty(searchedOrders.items) === false ? 'block': 'none'}}>
          { lt.max >= 12 &&
            <Header4>All Orders</Header4>
          }
          { _.isEmpty(customerInfo) === false && customerInfo.hasOwnProperty("group_id") && customerInfo.group_id === 4 &&
          <Row top={10} bottom={10} cross={Axis.cross.center} >
            <TextField
              placeholder="Customer name"
              margin="dense"
              id="serch"
              type="text"
              variant="standard"
              onChange={searchFieldChangeHandler}
              inputRef={searchRef}
            />
            <Row right={20} >
              <Button onClick={searchHandler}>Search</Button>
            </Row>  
          </Row>
          }
          <Column top={lt.max === 4 || lt.max === 8 ? 0: 30} bottom={lt.max === 4 || lt.max === 8 ? 20: 40}>
            {_.isEmpty(searchedOrders) === false && searchedOrders.items.map((v,i)=>{
              product_options = JSON.parse(v.product_options);
              //console.log(product_options);
              return (
                <Column key={uuid()} top={10} bottom={10} >
                  <Paper elevation={2}>
                    <Column left={lt.max === 4 ? 20: 30} right={lt.max === 4 ? 20: 30} top={30} bottom={30}>
                      { _.isEmpty(customerInfo) === false && customerInfo.hasOwnProperty("group_id") && customerInfo.group_id === 4 &&
                      <Row self={Axis.self.end} top={5} bottom={15}>
                        <Button variant='contained' size='small' sx={{color:'white'}} data-data={JSON.stringify(v)} onClick={printHandler}>Print</Button>
                      </Row>
                      }
                      <Row main={Axis.main.between} bottom={15}>
                        <Column>
                          <Row><Subtitle2>Order #</Subtitle2></Row>
                          <Row><Subtitle2>Date</Subtitle2></Row>
                          <Row><Subtitle2>Status</Subtitle2></Row>
                        </Column>
                        
                        <Column>
                          <Row main={Axis.main.end}><Subtitle2>{v.number}</Subtitle2></Row>
                          <Row main={Axis.main.end}><Subtitle2>{v.date}</Subtitle2></Row>
                          <Row main={Axis.main.end}><Subtitle2>{v.label}</Subtitle2></Row>
                        </Column>
                      </Row>
                      <Divider></Divider>
                      <Column top={10}>
                        <table style={{width:'100%', border: 'none'}}>
                          <tbody>
                            <tr><td><Row><Body1>Pieces</Body1></Row></td><td><Row left={10}><Body1>{parseInt(product_options.info_buyRequest.qty)}</Body1></Row></td></tr>
                          
                        {_.isEmpty(product_options) === false && product_options.options.map((o,k)=>{
                          let m = {label:o.label, value:o.value};
                          if (m.label === "Sales") {
                            return null;
                          }
                          if (m.label.includes("-")) {
                            m.label = "Style";
                          }
                          return(
                            <tr key={uuid()}>
                              <td style={{verticalAlign: "top"}}><Row top={2.5} bottom={2.5}><Body1>{m.label}</Body1></Row></td>
                              <td style={{verticalAlign: "top"}}><Row top={2.5} bottom={2.5} left={10}><Body1>{m.value.replace(/&quot;/g, '"')}</Body1></Row></td>
                            </tr>
                          );
                          
                        })}
                          </tbody>
                        </table>
                      </Column>
                    </Column>
                  </Paper>
                </Column>
              );
            })}
          </Column>
          { page !== 0 &&
            <Pagination count={totalPages} page={page} onChange={handleChangePage} />
          }
        </div>
        <div style={{display: _.isEmpty(searchedOrders) === false && searchedOrders.hasOwnProperty('items') && _.isEmpty(searchedOrders.items) === true ? 'block': 'none'}}>
        <Header5>No orders</Header5>
        </div>
      </div>
      <div style={{display: _.isEmpty(searchedOrders) ? 'block': 'none'}}>
        {customerOrders === null ? 
        <Row cross={Axis.cross.center}><Header3>Please wait</Header3><Row left={20}><CircularProgress size={30}></CircularProgress></Row></Row>
        :
        <Header5>No orders</Header5>
        }
      </div>
    </Column>
  );
}


function MyAddressBook(props) {
  //const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;
  const addresses = stateObj.addresses;
  const countries = stateObj.countries;

  useEffect(()=>{

  }, [JSON.stringify(addresses), JSON.stringify(countries)]);

  return (
    <Column>
      { lt.max >= 12 &&
        <Header4>Address Book</Header4>
      }
      <Column top={lt.max === 4 ? 0: 40}>
        <AddressList addresses={ addresses } countries={ countries }></AddressList>
      </Column>
    </Column>
  );
}

function AccountSettings(props) {
  return (
    <Header4>Account Settings</Header4>
  );
}

function LogOut(props) {
  const dispatch = useDispatch();
  return (
    <Column>
      <Header4>Log Out</Header4>
      <Row top={40} >
        <Button color='primary' size="large" variant='contained' sx={{color: "#fff"}} onClick={()=>{
          dispatch(logOutMagento());
        }}>Log Out</Button>
      </Row>
    </Column>
    
  );
}

function MainMenu(props) {
  const stateObj = useSelector((state) => state.mainState);
  const lt = stateObj.lt;

  return(
    <Row>
        <HeaderNav width={window.innerWidth+"px"}>
          <Row width={lt.innerWidth}>
            {props.children}
          </Row>
        </HeaderNav>
    </Row>
  );
}


function ContactUsMenu() {

  const handleClick = (event) => {
    
  };

  return(
    <Typography>
      <HeaderMenuButton
        onClick={handleClick}
      >
        Contact Us
      </HeaderMenuButton>
    </Typography>
  );
}


function AddressList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const customerToken = stateObj.customerToken;
  const errMessage = stateObj.errMessage;
  const errType = stateObj.errType;
  const addresses = stateObj.addresses;
  const [checkNewAddress, setCheckNewAddress] = React.useState(false);

  useEffect(()=>{
    setCheckNewAddress(false);
  }, [JSON.stringify(addresses)]);


  const deleteAddress = (event) => {
    dispatch(deleteCustomerAddress(customerToken, event.currentTarget.dataset.id));
  }

  const setAsDefaultForShippingHandler = (event) => {
    const addressId = event.currentTarget.dataset.id;
    dispatch(setCustomerAddressesAsDefalut(customerToken, addressId, true, 0));
  }

  const setAsDefaultForBillingHandler = (event) => {
    const addressId = event.currentTarget.dataset.id;
    dispatch(setCustomerAddressesAsDefalut(customerToken, addressId,  true, 1));
  }

  let content = [];
  
  let i = 0;
  for (let addr of props.addresses) {
    i++;
    let company = "";
    let street = "";
    let city = "";
    let region = "";
    let zipCode = "";
    let countryCode = "";
    let countryName = "";
    let phone = "";
    let name = "";
    let addressAsString = "";
    if (addr.hasOwnProperty('firstname') && addr.hasOwnProperty('lastname')) {
      name = addr.firstname + " " + addr.lastname;
    }
    if (addr.hasOwnProperty('company')) {
      company = addr.company;
    }
    if (addr.hasOwnProperty('street')) {
      for (let item of addr.street) {
        street += item + ",";
      }
      if (street.length > 1) {
        street = street.substring(0, street.length-1);
      }
    }
    if (addr.hasOwnProperty('city')) {
      city = addr.city;
    }
    if (addr.hasOwnProperty('region')) {
      region = addr.region.region;
      if (region === null) {
        region = "";
      }
    }
    if (addr.hasOwnProperty('postcode')) {
      zipCode = addr.postcode;
    }
    if (addr.hasOwnProperty('country_code')) {
      countryCode = addr.country_code;
      let i = _.findIndex(props.countries, function(o) { return o.id === countryCode; });
      if (i != -1) {
        countryName = props.countries[i].full_name_english;
      }
    }
    if (addr.hasOwnProperty('telephone')) {
      phone = addr.telephone;
    }

    if (name) {
      addressAsString += name + ", ";
    }
    if (company) {
      addressAsString += company + ", ";
    }
    if (street) {
      addressAsString += street + ", ";
    }
    if (city) {
      addressAsString += city + ", ";
    }
    if (region) {
      addressAsString += region + ", ";
    }
    if (zipCode) {
      addressAsString += zipCode + ", ";
    }
    if (countryName) {
      addressAsString += countryName + ", ";
    }
    if (phone) {
      addressAsString += "tel.: "+phone;
    }

    let subtitle = "";
    if (addr.default_billing && addr.default_shipping) {
      subtitle = "This address is default for shipping and billing";
    } else if (addr.default_billing && !addr.default_shipping) {
      subtitle = "This address is default for billing";
    } else if (!addr.default_billing && addr.default_shipping) {
      subtitle = "This address is default for shipping";
    }

    let titleForUsingButton="Use This Address For Shipping";
    if (!props.forShipping) {
      titleForUsingButton="Use This Address For Billing"
    }

    content.push(
      <Column bottom={10} key={"addr"+i}>
        <Row top={15} bottom={1}>
          <Body2>{addressAsString}</Body2>
        </Row>
        { subtitle !== "" && <Row top={7} bottom={2} ><Subtitle2>{subtitle}</Subtitle2></Row>}
        { !addr.default_shipping && <Row top={15} bottom={5}><Button variant='outlined' sx={{ minWidth:200, height:30, fontSize:12, fontWeight:400}} data-id={addr.id} onClick={setAsDefaultForShippingHandler}>Set As Default For Shipping</Button></Row>}
        { !addr.default_billing && <Row top={5} bottom={5}><Button variant='outlined' sx={{minWidth:200, height:30, fontSize:12, fontWeight:400}} data-id={addr.id} onClick={setAsDefaultForBillingHandler}>Set As Default For Billing</Button></Row>}
        <Row top={20} bottom={20} main={Axis.main.end}>
          <Row>
            <IconButton aria-label="delete" data-id={addr.id} onClick={deleteAddress}><DeleteIcon /></IconButton>
          </Row>
        </Row>
        <Divider></Divider>
      </Column>
    );
  }

  //<Checkbox checked={checkNewAddress} onChange={addNewAddressHandler}/>
  

  const addNewAddressHandler = (event) => {
    setCheckNewAddress(event.currentTarget.checked);
  }

  return (
    <>
      { (props.addresses.length === 0) &&
        <Column>
          <FormGroup>
            <FormControlLabel control={<Switch checked={checkNewAddress} onChange={addNewAddressHandler} inputProps={{ 'aria-label': 'controlled' }} />} label="Add New Address" />
          </FormGroup>
          { checkNewAddress && 
            
            <div><NewAddressForm></NewAddressForm></div>
          }
        </Column>
      }
      <div style={{display: (props.addresses.length === 0) ? 'none' : 'block'}}>  
        <Column>
          <Divider></Divider>
          <Row bottom={10}></Row>
          {content}
          <Column >
            <FormGroup>
            <FormControlLabel control={<Switch checked={checkNewAddress} onChange={addNewAddressHandler} inputProps={{ 'aria-label': 'controlled' }} />} label="Add New Address" />
            </FormGroup>
            { checkNewAddress && 
              <div><NewAddressForm></NewAddressForm></div>
            }
          </Column>
        </Column>
      </div>
    </>
  );
}








export default ProfileView16;